<template>
  <div class="gallery">
    <BRow gutter-x="4" gutter-y="4" alignContent="center" alignH="center" alignV="center">
      <BCol :sm="gridSm" v-for="(item, index) in galleryItems" :key="index">
        <div class="gallery-item">
          <div class="gallery-item-image">
            <BImg
              :src="item.thumbnail"
              :alt="item.title"
              :class="`type-` + item.type"
              @click="openLightboxOnSlide(index + 1)"
              rounded
            />
            <div class="gallery-item-category" v-if="item.category">{{ item.category }}</div>
          </div>
          <div class="gallery-item-overlay">
            <BootstrapIcon icon="zoom-in" />
          </div>
        </div>
      </BCol>
    </BRow>
  </div>
  <FsLightbox :toggler="gallery" :slide="gallerySlideIndex" :sources="galleryItemsSources" />
</template>
<script>
import FsLightbox from "fslightbox-vue/v3";

export default {
  name: "Gallery",
  components: { FsLightbox },
  props: {
    items: {
      type: Array,
    },
    categories: {
      type: Array,
    },
    filterItemsByCategory: {
      type: String,
      default: "all",
    },
    gridSm: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      gallery: false,
      gallerySlideIndex: 1,
      galleryItems: [],
      importedItems: [],
    };
  },

  mounted() {
    this.importFromFolder(require.context("@/assets/gallery/", true, /\.jpg$/));

    this.loadImportedItemsToGalleryItems();

    this.shuffle(this.galleryItems);
  },

  computed: {
    galleryItemsSources() {
      return this.getGalleryArray("src");
    },

    galleryItemsThumbnails() {
      return this.getGalleryArray("thumbnail");
    },
  },

  methods: {
    importFromFolder(r) {
      r.keys().forEach((key) => this.importedItems.push({ pathLong: r(key), pathShort: key }));
    },

    loadImportedItemsToGalleryItems() {
      for (let i = 0; i < this.importedItems.length; i++) {
        const item = this.importedItems[i];
        this.galleryItems.push({
          title: "",
          thumbnail: item.pathLong,
          src: item.pathLong,
          category: null,
        });
      }
    },

    // getRandomGalleryCategory() {
    //   return this.categories[Math.floor(Math.random() * this.categories.length)];
    // },

    openLightboxOnSlide(index) {
      this.gallerySlideIndex = index;
      this.gallery = !this.gallery;
    },

    getGalleryArray(key) {
      let result = [];

      this.galleryItems.forEach((item) => {
        result.push(item[key]);
      });

      return result;
    },

    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }

      return array;
    },

    // filterGalleryItemsByCategory(items, category) {
    //   if (category === "all") return items;
    //
    //   const filteredItems = [];
    //
    //   items.forEach((item) => {
    //     if (item.category === category) {
    //       filteredItems.push(item);
    //     }
    //   });
    //
    //   return filteredItems;
    // },
  },
};
</script>
<style scoped lang="scss">
.gallery {
  &-item {
    position: relative;
    cursor: pointer;
    box-shadow: $box-shadow;

    &-image {
      height: $card-image-max-height;

      img {
        object-fit: cover;
        border-radius: $border-radius;
      }
    }

    &-category {
      position: absolute;
      right: 0;
      top: $spacer;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      line-height: 1;
      background-color: rgba($primary, 0.8);
      border-radius: $border-radius 0 0 $border-radius;
      padding: calc(#{$spacer} / 2);

      @include set-font-style($font-size-level-3, $font-weight-bold, $white);
    }

    &-overlay {
      position: absolute;
      z-index: $zindex-dropdown;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($gray-900, 0.7);
      border-radius: $border-radius;
      color: $white;
      opacity: 0;
      transition: $transition-base;
      pointer-events: none;

      .bi {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: $font-size-level-6;
      }
    }

    &:hover {
      .gallery-item-overlay {
        opacity: 1;
        transition: $transition-base;
      }
    }
  }
}
</style>
