<template>
  <SectionBlock>
    <SectionTitle v-if="sectionTitle" :title="sectionTitle" />
    <SectionContent>
      <Gallery
        :gridSm="galleryGridSm"
        :items="this.galleryItems"
        :categories="this.galleryCategories"
        :filterItemsByCategory="filterGalleryItemsByCategory"
      />
    </SectionContent>
  </SectionBlock>
</template>
<script>
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import Gallery from "@/components/units/Gallery";

export default {
  name: "SectionGallery",
  components: { Gallery, SectionContent, SectionTitle, SectionBlock },
  props: {
    sectionTitle: {
      type: String,
    },
    galleryGridSm: {
      type: Number,
    },
    filterGalleryItemsByCategory: {
      type: String,
      default: "all",
    },
  },
};
</script>

<style scoped lang="scss"></style>
