<template>
  <PrimaryMenu />

  <Header title="Galéria" />

  <SectionGallery sectionTitle="A vezetés élmény..." />

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";
import SectionGallery from "@/components/layout/sections/SectionGallery";

export default {
  name: "Gallery",
  title: "Galéria",
  components: {
    SectionGallery,
    PrimaryMenu,
    Header,
    SectionContact,
    Footer,
  },
};
</script>

<style scoped lang="scss"></style>
